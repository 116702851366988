import React from "react"
import Layout from "../components/organisms/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import ActionLink from "../components/atoms/action-paragraph-link"
import { graphql } from "gatsby"

const Wraper = styled.section`
  display: flex;
  min-height: 60vh;
  width: 100%;
  padding: 15vh 20vw;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  z-index: 1000;

  @media (max-width: 700px) {
    padding: 10vh 15vw;
  }

  @media (max-width: 500px) {
    padding: 10vh 10vw;
  }
`

const Heading = styled.h1`
  font-size: 5rem;
  font-weight: 500;
`

const SubHeading = styled.h2`
  font-size: 3rem;
  color: ${({ theme }) => theme.pink};
  font-weight: 500;
  margin: 0 0 5vh;
`

const Content = styled.p`
  font-size: 2rem;
`

const NotFoundPage = ({ data }) => (
  <Layout
    cert1={data.cert1.childImageSharp.fluid}
    cert2={data.cert2.childImageSharp.fluid}
  >
    <SEO title="404: Brak zawartości" />
    <Wraper>
      <Heading>Nie znaleziono treści</Heading>
      <SubHeading>Bład 404</SubHeading>
      <Content>
        Strona której szukasz niestety nie istnieje. Prosimy o przejście na{" "}
        <ActionLink to={"/"} title={"Strona główna"}>
          stronę główną.
        </ActionLink>
      </Content>
    </Wraper>
  </Layout>
)

export const query = graphql`
  query {
    cert1: file(relativePath: { eq: "cambridge-english-assessment.png" }) {
      childImageSharp {
        fluid(maxWidth: 384, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    cert2: file(relativePath: { eq: "direct-method.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 244, maxHeight: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default NotFoundPage
